import React from 'react';
import Map from '../components/map'
import {db,getUser,storage} from '../config/firebase'
import {getDocs,collection, query, where,addDoc, deleteDoc, updateDoc, doc} from "firebase/firestore"
import Nav from '../components/nav'
import {ref,uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
import {v4} from "uuid"
import Form from '../components/form'
import { Link } from 'react-router-dom';


const propTypes = {};

const defaultProps = {};

function formatCurrency(value) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(value);
}

class MyLocations extends React.Component {
constructor(props) {
    super(props);

    this.state = {
        user:{},
        scroll:0,
        sponser:true,
        token:"",
        accuracy:0,
        page:"",
        latitude:37.091024,
        longitude:-113.577932,
        altitude:0,
        radius:10,
        GEOID:null,
        arrived:false,
        locationButtonColor:"bg-green-600 hover:bg-green-700",
        gettingLocation:false,
        cannotgettingLocation:false,
        locations:[],
        locationInputScreen:false,
        buttonDisable:false,
        locationInfo:{},
        position:{
            latitude:37.091029,
            longitude:-113.577933,
            altitude:10,
        },
        errorMsg:"",
        logoFile:null,
        img1File:null,
        img2File:null,
        logoURL:"",
        img1URL:"",
        img2URL:"",
        resetPin:false,
        locationConfirmed:false,
        editing:false,
        newLocationPin:false,
        selectedLocation:{},
        spendablePoints:0,
        awardablePoints:0,
        formFeilds: { 
            locationName:{
                type:"text",
                value:"",
                label:"Name*",
                placeHolder:"Name..."
                },
            description:{
                type:"textarea",
                value:"",
                label:"Description*",
                placeHolder:"Description..."
                },
            website:{
                type:"text",
                label:"Website Url",
                placeHolder:"Website...",
                value:"",
                },                      
           
            category:{
                type:"select",
                value:[
                    "Activity",
                    "Antiques",
                    "Arcade",
                    "Art Gallery",
                    "Bar",
                    "Beach",
                    "Books",
                    "Botanical Garden",
                    "Building",
                    "Campground",
                    "Cinema",
                    "Clothing",
                    "Concert",
                    "Convenience stores",
                    "Dance",
                    "Electronics",
                    "Entertainment",
                    "Event",
                    "Food and beverage",
                    "Food Truck",
                    "Gift shops",
                    "Golf",
                    "Health and beauty",
                    "Historical Site",
                    "Home improvement",
                    "Jewelry",
                    "Market",
                    "Monument",
                    "Museum",
                    "Music",
                    "National Park",
                    "Nightclub",
                    "Outdoors",
                    "Pets",
                    "Park",
                    "Restaurant",
                    "Shopping Mall",
                    "Ski Resort",
                    "Sports",
                    "Supermarkets/grocery stores",
                    "Theater",
                    "Theme Park",
                    "Thrift",
                    "Trail",
                    "Toys",
                    "University",
                    "Zoo"
                  ],
                label:[
                    "Activity",
                    "Antiques",
                    "Arcade",
                    "Art Gallery",
                    "Bar",
                    "Beach",
                    "Books",
                    "Botanical Garden",
                    "Building",
                    "Campground",
                    "Cinema",
                    "Clothing",
                    "Concert",
                    "Convenience stores",
                    "Dance",
                    "Electronics",
                    "Entertainment",
                    "Event",
                    "Food and beverage",
                    "Food Truck",
                    "Gift shops",
                    "Golf",
                    "Health and beauty",
                    "Historical Site",
                    "Home improvement",
                    "Jewelry",
                    "Market",
                    "Monument",
                    "Museum",
                    "Music",
                    "National Park",
                    "Nightclub",
                    "Outdoors",
                    "Pets",
                    "Park",
                    "Restaurant",
                    "Shopping Mall",
                    "Ski Resort",
                    "Sports",
                    "Supermarkets/grocery stores",
                    "Theater",
                    "Theme Park",
                    "Thrift",
                    "Trail",
                    "Toys",
                    "University",
                    "Zoo"
                  ],
                placeHolder:"Category",
                selected:"Activity",
                },
            sunday:{
                type:"hourrange",
                placeHolder:"Sunday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            monday:{
                type:"hourrange",
                placeHolder:"Monday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            tuesday:{
                type:"hourrange",
                placeHolder:"Tuesday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            wednesday:{
                type:"hourrange",
                placeHolder:"Wednesday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            thursday:{
                type:"hourrange",
                placeHolder:"Thursday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            friday:{
                type:"hourrange",
                placeHolder:"Friday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            saturday:{
                type:"hourrange",
                placeHolder:"Saturday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            agreement:{
                type:"checkbox",
                value:[false,false],
                label:["I have read and agree to the terms and conditions.","I have authorization to allow people onto this land."],
                placeHolder:""
                },
            
        }
    };
}

async buyPoints(){
    return new Promise(async (resolve, reject)  => {
        if(this.state.movingPoints<10){
            this.setState({errorMsg:"You must buy at least 10 points."})
            reject()
            return
        }

        let body = {
            requestedPoints:this.state.movingPoints,
        }
        
        let bodyjson = JSON.stringify(body);
        
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/buyPoints`, {
            method: "POST",
            body:bodyjson,
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
        window.location = data.url
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        resolve(data)
    })
}

async awardPoints(){
    return new Promise(async (resolve, reject)  => {
        if(this.state.movingPoints<1){
            this.setState({errorMsg:"You must award at least 1 point(s)."})
            reject()
        }

        if(this.state.phone== this.state.user.phoneNumber){
            this.setState({errorMsg:"You can not award yourself"})
            reject()
        }

        if(this.state.phone.length<10 || this.state.phone.length>15){
            this.setState({errorMsg:"Please enter a valid phne number"})
            reject()
        }
        let body = {
            numberPoints: this.state.movingPoints,
            awardedPhone: this.state.phone,
        }
        let bodyjson = JSON.stringify(body);
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/awardPoints`, {
            method: "POST",
            body:bodyjson,
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
        this.setState({awardablePoints:data.totalPoints, page:""})
   
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        resolve(data)
    })
}

loadHours(locationId){
    return new Promise(async (resolve, reject) =>  {
        const hoursOpen = collection(db,"hoursOpen")
        const q = query(hoursOpen,where("locationId", "==", locationId))
        let hourLocationIndex = []
        getDocs(q).then((data)=>{
            const filteredData = data.docs.map((doc)=>({
                ...doc.data(),
                id: doc.id
            }))
            resolve(filteredData)
        }).catch((error)=>{
            console.log(error)
        })   
    })
}

clearForm(){
    return new Promise(async (resolve, reject) =>  {
        let newPosition ={}
        let newForm = { 
            locationName:{
                type:"text",
                value:"",
                label:"Name*",
                placeHolder:"Name..."
                },
            description:{
                type:"textarea",
                value:"",
                label:"Description*",
                placeHolder:"Description..."
                },
            website:{
                type:"text",
                label:"Website Url",
                placeHolder:"Website...",
                value:"",
                }, 
            category:{
                type:"select",
                value:[
                    "Activity",
                    "Antiques",
                    "Arcade",
                    "Art Gallery",
                    "Bar",
                    "Beach",
                    "Books",
                    "Botanical Garden",
                    "Building",
                    "Campground",
                    "Cinema",
                    "Clothing",
                    "Concert",
                    "Convenience stores",
                    "Dance",
                    "Electronics",
                    "Entertainment",
                    "Event",
                    "Food and beverage",
                    "Food Truck",
                    "Gift shops",
                    "Golf",
                    "Health and beauty",
                    "Historical Site",
                    "Home improvement",
                    "Jewelry",
                    "Market",
                    "Monument",
                    "Museum",
                    "Music",
                    "National Park",
                    "Nightclub",
                    "Outdoors",
                    "Pets",
                    "Park",
                    "Restaurant",
                    "Shopping Mall",
                    "Ski Resort",
                    "Sports",
                    "Supermarkets/grocery stores",
                    "Theater",
                    "Theme Park",
                    "Thrift",
                    "Trail",
                    "Toys",
                    "University",
                    "Zoo"
                  ],
                label:[
                    "Activity",
                    "Antiques",
                    "Arcade",
                    "Art Gallery",
                    "Bar",
                    "Beach",
                    "Books",
                    "Botanical Garden",
                    "Building",
                    "Campground",
                    "Cinema",
                    "Clothing",
                    "Concert",
                    "Convenience stores",
                    "Dance",
                    "Electronics",
                    "Entertainment",
                    "Event",
                    "Food and beverage",
                    "Food Truck",
                    "Gift shops",
                    "Golf",
                    "Health and beauty",
                    "Historical Site",
                    "Home improvement",
                    "Jewelry",
                    "Market",
                    "Monument",
                    "Museum",
                    "Music",
                    "National Park",
                    "Nightclub",
                    "Outdoors",
                    "Pets",
                    "Park",
                    "Restaurant",
                    "Shopping Mall",
                    "Ski Resort",
                    "Sports",
                    "Supermarkets/grocery stores",
                    "Theater",
                    "Theme Park",
                    "Thrift",
                    "Trail",
                    "Toys",
                    "University",
                    "Zoo"
                  ],
                placeHolder:"Category",
                selected:"Activity",
                },
            sunday:{
                type:"hourrange",
                placeHolder:"Sunday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            monday:{
                type:"hourrange",
                placeHolder:"Monday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            tuesday:{
                type:"hourrange",
                placeHolder:"Tuesday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            wednesday:{
                type:"hourrange",
                placeHolder:"Wednesday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            thursday:{
                type:"hourrange",
                placeHolder:"Thursday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            friday:{
                type:"hourrange",
                placeHolder:"Friday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            saturday:{
                type:"hourrange",
                placeHolder:"Saturday Hours",
                selectedStart:-1,
                selectedEnd:0,
                },
            agreement:{
                type:"checkbox",
                value:[false,false],
                label:["I have read and agree to the terms and conditions.","I have authorization to allow people onto this land."],
                placeHolder:""
                },
        }
            
        this.setState({
            position:newPosition,
            formFeilds:newForm,
            editing:true,
            logoFile:null,
            img1File:null,
            img2File:null,
            logoURL:"",
            img1URL:"",
            img2URL:"",
            resetPin:false,
            locationConfirmed:false,
            editing:false,
            newLocationPin:false,
            selectedLocation:{},
            buttonDisable:false,
            locationInfo:{},
            position:{},
        },()=>{
            resolve(true)
        })

    }).catch((error)=>{
        console.log(error)
    })

}

autoFillFor(location){
    return new Promise(async (resolve, reject) =>  {
       
            let newPosition ={
                altitude:location.altitude,
                latitude:location.latitude,
                longitude:location.longitude,
            }
            let newForm = { 
                locationName:{
                    type:"text",
                    value:location.name,
                    label:"Name*",
                    placeHolder:"Name..."
                    },
                description:{
                    type:"textarea",
                    value:location.description,
                    label:"Description*",
                    placeHolder:"Description..."
                    },
                website:{
                    type:"text",
                    label:"Website Url",
                    placeHolder:"Website...",
                    value:location.website,
                    }, 
                category:{
                    type:"select",
                    value:[
                        "Activity",
                        "Antiques",
                        "Arcade",
                        "Art Gallery",
                        "Bar",
                        "Beach",
                        "Books",
                        "Botanical Garden",
                        "Building",
                        "Campground",
                        "Cinema",
                        "Clothing",
                        "Concert",
                        "Convenience stores",
                        "Dance",
                        "Electronics",
                        "Entertainment",
                        "Event",
                        "Food and beverage",
                        "Food Truck",
                        "Gift shops",
                        "Golf",
                        "Health and beauty",
                        "Historical Site",
                        "Home improvement",
                        "Jewelry",
                        "Market",
                        "Monument",
                        "Museum",
                        "Music",
                        "National Park",
                        "Nightclub",
                        "Outdoors",
                        "Pets",
                        "Park",
                        "Restaurant",
                        "Shopping Mall",
                        "Ski Resort",
                        "Sports",
                        "Supermarkets/grocery stores",
                        "Theater",
                        "Theme Park",
                        "Thrift",
                        "Trail",
                        "Toys",
                        "University",
                        "Zoo"
                      ],
                    label:[
                        "Activity",
                        "Antiques",
                        "Arcade",
                        "Art Gallery",
                        "Bar",
                        "Beach",
                        "Books",
                        "Botanical Garden",
                        "Building",
                        "Campground",
                        "Cinema",
                        "Clothing",
                        "Concert",
                        "Convenience stores",
                        "Dance",
                        "Electronics",
                        "Entertainment",
                        "Event",
                        "Food and beverage",
                        "Food Truck",
                        "Gift shops",
                        "Golf",
                        "Health and beauty",
                        "Historical Site",
                        "Home improvement",
                        "Jewelry",
                        "Market",
                        "Monument",
                        "Museum",
                        "Music",
                        "National Park",
                        "Nightclub",
                        "Outdoors",
                        "Pets",
                        "Park",
                        "Restaurant",
                        "Shopping Mall",
                        "Ski Resort",
                        "Sports",
                        "Supermarkets/grocery stores",
                        "Theater",
                        "Theme Park",
                        "Thrift",
                        "Trail",
                        "Toys",
                        "University",
                        "Zoo"
                      ],
                    placeHolder:"Category",
                    selected:location.category,
                    },
                sunday:{
                    type:"hourrange",
                    placeHolder:"Sunday Hours",
                    selectedStart:location.sunStart,
                    selectedEnd:location.sunEnd,
                    },
                monday:{
                    type:"hourrange",
                    placeHolder:"Monday Hours",
                    selectedStart:location.monStart,
                    selectedEnd:location.monEnd,
                    },
                tuesday:{
                    type:"hourrange",
                    placeHolder:"Tuesday Hours",
                    selectedStart:location.tueStart,
                    selectedEnd:location.tueEnd,
                    },
                wednesday:{
                    type:"hourrange",
                    placeHolder:"Wednesday Hours",
                    selectedStart:location.wedStart,
                    selectedEnd:location.wedEnd,
                    },
                thursday:{
                    type:"hourrange",
                    placeHolder:"Thursday Hours",
                    selectedStart:location.thurStart,
                    selectedEnd:location.thurEnd,
                    },
                friday:{
                    type:"hourrange",
                    placeHolder:"Friday Hours",
                    selectedStart:location.fridStart,
                    selectedEnd:location.frirEnd,
                    },
                saturday:{
                    type:"hourrange",
                    placeHolder:"Saturday Hours",
                    selectedStart:location.satStart,
                    selectedEnd:location.satEnd,
                    },
                agreement:{
                    type:"checkbox",
                    value:[false,false],
                    label:["I have read and agree to the terms and conditions.","I have authorization to allow people onto this land."],
                    placeHolder:""
                    },
                
            }
            this.setState({
                position:newPosition,
                formFeilds:newForm,
                editing:true,
                radius:location.radius,
                logoURL:location.logoURL,
                img1URL:location.img1URL,
                img2URL:location.img2URL,
            },()=>{

                resolve(true)
            })

    })
}



loadLocations(){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/locations/my?currentLat=${this.state.latitude}&currentLong=${this.state.longitude}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
    
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({locations:data})
        resolve()
    })
}

uploadFile(file,folder,name){
    return new Promise(async (resolve, reject) =>  {
        if(file){
            const fileRef = ref(storage,folder+"/"+name+v4())
            const metadata = { contentType: 'image/jpeg' };
            uploadBytes(fileRef,file,metadata).then((res)=>{
        
                getDownloadURL(res.ref).then((url)=>{
                    resolve(url)
                })
            })
        }else{
            resolve(false)
        }  
    })
}

deleteFile(imageURL,imageSpot,locationId){
    return new Promise(async (resolve, reject) =>  {
        if(imageURL){
            let splitUrl = imageURL.split("/")
            let imageNameEncoded = splitUrl[splitUrl.length-1].split("?")[0]
            let imageName = decodeURI(imageNameEncoded)
            let imageFileName = imageName.split("%2F")
            // console.log(imageFileName[0]+"/"+imageFileName[1])
            const fileRef = ref(storage,imageFileName[0]+"/"+imageFileName[1])
            deleteObject(fileRef).then(async (res)=>{
                // console.log(res)
                const locationDoc = doc(db, "locations", locationId)
                if(imageSpot==='logo'){
                    await updateDoc(locationDoc, {logoURL:""})
                    let newSelected = this.state.selectedLocation
                    newSelected.logoURL =""
                    this.setState({logoURL:"",selectedLocation:newSelected})
                } else if(imageSpot==='img1'){
                    await updateDoc(locationDoc, {img1URL:""})
                    let newSelected = this.state.selectedLocation
                    newSelected.img1URL =""
                    this.setState({img1URL:"",selectedLocation:newSelected})
                } else if(imageSpot==='img2'){
                    await updateDoc(locationDoc, {img2URL:""})
                    let newSelected = this.state.selectedLocation
                    newSelected.img2URL =""
                    this.setState({img2URL:"",selectedLocation:newSelected})
                }
                resolve(true)
            })
        }else{
            resolve(false)
        }  
    })
}

async createLocation(incomingData, img1URL, img2URL, logoURL) {
     return new Promise(async (resolve, reject) => {
        try {

            if (!this.state.position.latitude) {
                this.setState({ errorMsg: "You must provide latitude." });
                reject()
                return;
            }
            if (!this.state.position.longitude) {
                this.setState({ errorMsg: "You must provide longitude." });
                reject()
                return;
            }
            if (!this.state.position.altitude) {
                this.setState({ errorMsg: "You must provide altitude." });
                reject()
                return;
            }
            if (!this.state.radius) {
                this.setState({ errorMsg: "You must provide radius." });
                return;
            }

            const requiredFields = ['locationName', 'description', 'category'];
      
            for (const field of requiredFields) {
                if (!incomingData[field]) {
                    this.setState({ errorMsg: `You must provide ${field}.` });
                    reject(`You must provide ${field}.`);
                    return
                }
            }

            const body = {
                description: incomingData.description,
                img1URL: img1URL,
                img2URL: img2URL,
                logoURL: logoURL,
                altitude: this.state.position.altitude,
                latitude: this.state.position.latitude,
                longitude: this.state.position.longitude,
                name: incomingData.locationName,
                website: incomingData.website,
                radius: this.state.radius,
                category: incomingData.category,

                sunStart: incomingData.sunday.hourStart,
                sunEnd: incomingData.sunday.hourEnd,
                monStart: incomingData.monday.hourStart,
                monEnd: incomingData.monday.hourEnd,
                tueStart: incomingData.tuesday.hourStart,
                tueEnd: incomingData.tuesday.hourEnd,
                wedStart: incomingData.wednesday.hourStart,
                wedEnd: incomingData.wednesday.hourEnd,
                thuStart: incomingData.thursday.hourStart,
                thuEnd: incomingData.thursday.hourEnd,
                friStart: incomingData.friday.hourStart,
                friEnd: incomingData.friday.hourEnd,
                satStart: incomingData.saturday.hourStart,
                satEnd: incomingData.saturday.hourEnd,
            };


            const response = await fetch(`${process.env.REACT_APP_SERVER}/locations/new`, {
                method: "POST",
                body: JSON.stringify(body),
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token,
                },
            });

            const data = await response.json();

            if (data.error) {
                this.setState({ errorMsg: data.error });
                 reject(`Error creating location: ${data.error}`);
            }

            this.setState({ events: data });
            resolve(data.locationId) 
        } catch (error) {
            console.error("Error creating location:", error);
            this.setState({ errorMsg: "An error occurred while creating the location." });
             reject(`Error creating location: ${error}`);
        }

     })
    
}


async locationSave(data){
     return new Promise(async (resolve, reject) => {
        try{
            let img1URL=this.state.img2URL
            let img2URL=this.state.img1URL
            let logoURL=this.state.logoURL
            
            if(this.state.logoFile && !this.state.logoURL){
                logoURL = await this.uploadFile(this.state.logoFile,"locationLogos",data.locationName+" logo")
            }
            if(this.state.img1File && !this.state.img1URL){
                img1URL = await this.uploadFile(this.state.img1File,"locationImages",data.locationName+" img1")
            }
            if(this.state.img2File && !this.state.img2URL){
                img2URL = await this.uploadFile(this.state.img2File,"locationImages",data.locationName+" img2")
            }
            const locationId = await this.createLocation(data,img1URL,img2URL,logoURL)
            this.setState({locationInputScreen:false,buttonDisable:false},()=>{
                this.loadLocations()
            })
            resolve(locationId)
        } catch (error){
            console.log(error)
            reject(error)
        }
     })
    
}

async editLocation(locationId, incomingData, img1URL, img2URL, logoURL) {
    return new Promise(async (resolve, reject) => {
        try {
             if (!this.state.position.latitude) {
                this.setState({ errorMsg: "You must provide latitude." });
                reject()
                return;
            }
            if (!this.state.position.longitude) {
                this.setState({ errorMsg: "You must provide longitude." });
                reject()
                return;
            }
            if (!this.state.position.altitude) {
                this.setState({ errorMsg: "You must provide altitude." });
                reject()
                return;
            }
            if (!this.state.radius) {
                this.setState({ errorMsg: "You must provide radius." });
                return;
            }
            const requiredFields = ['locationName', 'description', 'category'];

            for (const field of requiredFields) {
                if (!incomingData[field]) {
                    this.setState({ errorMsg: `You must provide ${field}.` });
                    reject(`You must provide ${field}.`);
                    return; // Stop execution if a required field is missing
                }
            }

            const body = {
                description: incomingData.description,
                img1URL: img1URL,
                img2URL: img2URL,
                logoURL: logoURL,
                altitude: this.state.position.altitude,
                latitude: this.state.position.latitude,
                longitude: this.state.position.longitude,
                name: incomingData.locationName,
                website: incomingData.website,
                radius: this.state.radius,
                category: incomingData.category,

                sunStart: incomingData.sunday.hourStart,
                sunEnd: incomingData.sunday.hourEnd,
                monStart: incomingData.monday.hourStart,
                monEnd: incomingData.monday.hourEnd,
                tueStart: incomingData.tuesday.hourStart,
                tueEnd: incomingData.tuesday.hourEnd,
                wedStart: incomingData.wednesday.hourStart,
                wedEnd: incomingData.wednesday.hourEnd,
                thuStart: incomingData.thursday.hourStart,
                thuEnd: incomingData.thursday.hourEnd,
                friStart: incomingData.friday.hourStart,
                friEnd: incomingData.friday.hourEnd,
                satStart: incomingData.saturday.hourStart,
                satEnd: incomingData.saturday.hourEnd,
            };

            const response = await fetch(`${process.env.REACT_APP_SERVER}/locations/edit/${locationId}`, {
                method: "PUT",
                body: JSON.stringify(body),
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token,
                },
            });

            const data = await response.json();

            if (data.error) {
                this.setState({ errorMsg: data.error });
                reject(data.error);
                return; // Stop execution if an error occurs
            }

            this.setState({ events: data });
            resolve(data.locationId);
        } catch (error) {
            console.error("Error editing location:", error);
            this.setState({ errorMsg: "An error occurred while editing the location." });
            reject("Error editing location: " + error);
        }
    });
}



async saveEditedLocation(data,id){
    
    try{
        let img1URL=this.state.img1URL 
        let img2URL=this.state.img2URL
        let logoURL=this.state.logoURL
       
        if(this.state.logoFile && !this.state.logoURL){
        
            logoURL = await this.uploadFile(this.state.logoFile,"locationLogos",data.locationName+" logo")
     
        }
         if(this.state.img1File && !this.state.img1URL ){
            img1URL = await this.uploadFile(this.state.img1File,"locationImages",data.locationName+" img1")
        }
        if(this.state.img2File && !this.state.img2URL){
            img2URL = await this.uploadFile(this.state.img2File,"locationImages",data.locationName+" img2")
        }

        await this.editLocation(id, data, img1URL, img2URL, logoURL)
        this.setState({logoFile:null,img1File:null,img2File:null,locationInputScreen:false,buttonDisable:false},()=>{
            this.loadLocations()
        })
        
    } catch (error){
        console.log(error)
    }
}

archiveLocation(locationId){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/locations/archive/${locationId}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
  
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({locations:data})
        resolve()
    })
}

async deleteLocation(location){
    this.archiveLocation(location.locationId)
}

toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }


  checkLocation() {
        this.getLocationAverage().then(()=>{
            
            const radius = this.state.radius; // 50 feet
            const earthRadius = 6371000; // meters
            const latDistance = this.toRadians(this.state.position.latitude - this.state.latitude);
            const lonDistance = this.toRadians(this.state.position.longitude - this.state.longitude);
            const a =
            Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
            Math.cos(this.toRadians(this.state.latitude)) *
                Math.cos(this.toRadians(this.state.longitude)) *
                Math.sin(lonDistance / 2) *
                Math.sin(lonDistance / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = earthRadius * c;
            let arrived = distance <= radius
   
            if(arrived){
                this.setState({arrived:arrived,locationButtonColor:"bg-green-600 hover:bg-green-700",})
            } else {
                this.setState({arrived:arrived,locationButtonColor:"bg-red-700 hover:bg-red-800"})
            }
            
            return arrived;  

        })
        

  }

//   checkLocation() {
//     if(this.state.position!=={}){
//         let currentLongitude = this.state.longitude //x
//         let currentLatitude = this.state.latitude //y
//         const radius = this.state.radius; // 50 feet
//         const distance = Math.sqrt((this.state.position.longitude - currentLongitude) ** 2 + (this.state.position.latitude - currentLatitude) ** 2);
//         let arrived = distance <= radius*0.00001;
        
//         if(arrived){
//             this.setState({arrived:arrived,locationButtonColor:"bg-green-600 hover:bg-green-700",})
//         } else {
//             this.setState({arrived:arrived,locationButtonColor:"bg-red-700 hover:bg-red-800"})
//         }
        
//         return arrived;  
        
//     }
//   }

  sortLocationsByDistance(currentLat, currentLong, locations) {
    return new Promise(async (resolve, reject) =>  {
        // Loop through each location and calculate the distance to the current location
        const sortedLocations = locations.map(location => {
            const lat1 = this.toRadians(currentLat);
            const lon1 = this.toRadians(currentLong);
            const lat2 = this.toRadians(location.latitude);
            const lon2 = this.toRadians(location.longitude);

            const dlon = lon2 - lon1;
            const dlat = lat2 - lat1;

            const a =
            Math.pow(Math.sin(dlat / 2), 2) +
            Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = 6371 * c; // Earth's radius is approximately 6,371 kilometers

            return { ...location, distance };
        });

        // Sort the locations by distance
        sortedLocations.sort((a, b) => a.distance - b.distance);

        resolve(sortedLocations);
    }) 
  }

getLocation(){
    return new Promise(async (resolve, reject) =>  {
        const options = {
            enableHighAccuracy: true,
            maximumAge: 0,
          };
        const success = (pos) => {
            const crd = pos.coords;
      
          
            this.setState({
            gettingLocation:true,
            accuracy:crd.accuracy,
            latitude:crd.latitude,
            longitude:crd.longitude,
            altitude:crd.altitude,
        },()=>{
            resolve(true)
        })  
           
        }
        const error = (err) => {
            console.warn(`ERROR(${err.code}): ${err.message}`);
            this.setState({cannotgettingLocation:true})
            
            reject(err)
        }
        let GEOID = navigator.geolocation.getCurrentPosition(success, error, options);
        this.setState({GEOID:GEOID})

    })
}

getLocationAverage() {
    return new Promise(async (resolve, reject) => {
      const options = {
        enableHighAccuracy: true,
        maximumAge: 5000,
        timeout: 10000,
      };
      let collectGoal = 20
      let count = 0;
      let lat = 0;
      let long = 0;
      let acc = 0;
      let alt = 0;
      let altAcc = 0;
      let head = 0;
      let speed = 0;
      const startTime = new Date()
  
      const success = (pos) => {
        
        const crd = pos.coords;
        
  
        lat += crd.latitude;
        long += crd.longitude;
        acc += crd.accuracy;
        alt += crd.altitude;
        altAcc += crd.altitudeAccuracy;
        head += crd.heading;
        speed += crd.speed;

        count++;
  
        if (count === collectGoal || new Date() - startTime >= 5000) {
          lat /= count;
          long /= count;
          acc /= count;
          alt /= count;
          altAcc /= count;
          head /= count;
          speed /= count;
  
          if (
            this.state.latitude !== lat ||
            this.state.longitude !== long ||
            this.state.heading !== head ||
            this.state.speed !== speed
          ) {
            this.setState(
              {
                newSnap: false,
                accuracy: acc,
                latitude: lat,
                longitude: long,
                altitude: alt,
                altitudeAccuracy: altAcc,
                heading: head,
                speed: speed,
                locationFeedBack: "RePin My location",
              },
              () => {
                this.stopGeoWatch()
              }
            );
          } else {
            
            this.stopGeoWatch()
            this.setState({
              locationFeedBack: "Please move around for a better reading.",
            });
          }
          resolve(true);
        } 
        // else if(count == 1){
        //     console.log("location set")
        //     this.setState(
        //         {
        //           
        //           newSnap: false,
        //           accuracy: crd.accuracy,
        //           latitude: crd.latitude,
        //           longitude: crd.longitude,
        //           altitude: crd.altitude,
        //           altitudeAccuracy: crd.altitudeAccuracy,
        //           heading: crd.heading,
        //           speed: crd.speed,
        //         },
        //         () => {
                  
        //         }
        //       );
        // }
      };
  
      const error = (err) => {
        const { code } = err;
        let feedback = ""
        switch (code) {
            case "TIMEOUT":
            // Handle timeout.
            
            break;
            case "PERMISSION_DENIED":
            // User denied the request.
            feedback = "Please grant location permissions to this site"
            break;
            case "POSITION_UNAVAILABLE":
            // Position not available.
            feedback = "Cannot get Location"
            break;
        }
        this.setState({ cannotgettingLocation: true, gettingLocation:false ,locationFeedBack:feedback});
        console.warn(`ERROR(${err.code}): ${err.message}`);
        reject(err);
        
      };
  
      let GEOID = navigator.geolocation.watchPosition(success, error, options);

      this.setState({ gettingLocation: true, GEOID: GEOID, locationFeedBack:"Please wait 5 seconds to verify your Location"  });
    });
  }
stopGeoWatch() {
    navigator.geolocation.clearWatch(this.state.GEOID);
    this.setState({GEOID:-1,gettingLocation:false})
  }


  changeLocationPoints(locationId,amount){
    console.log(amount)
    return new Promise(async (resolve, reject)  => {
        const body ={
            numberPoints:amount
        }
        const response = await fetch(`${process.env.REACT_APP_SERVER}/locations/addPoints/${locationId}`, {
            method: "PUT",
            body: JSON.stringify(body),
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
     
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        resolve()
    })
}


async savePoints(location){
    if((location.newAvailablePoints || location.newAvailablePoints ==0) && location.newAvailablePoints != location.availablePoints){
        await this.changeLocationPoints(location.locationId,location.newAvailablePoints)
        this.getUserPoints()
        this.loadLocations()
    }
}

async addPoints(index){
    let newLocations = this.state.locations
    let newUserPoints = {
        availableFree: this.state.awardablePoints,
        newAvailableFreePoints:this.state.awardablePoints
    }
   
    if(!newUserPoints.newAvailableFreePoints && newUserPoints.newAvailableFreePoints!=0){
        const points = newUserPoints.availableFree
        newUserPoints["newAvailableFreePoints"]=points
    }
    if( !newLocations[index].newAvailablePoints && newUserPoints.newAvailableFreePoints>=1){
        newLocations[index]["newAvailablePoints"]= newLocations[index].availablePoints + 1
        newUserPoints.newAvailableFreePoints = newUserPoints.newAvailableFreePoints - 1
    } else if( newUserPoints.newAvailableFreePoints>=1) {
        newLocations[index].newAvailablePoints= newLocations[index].newAvailablePoints + 1
        newUserPoints.newAvailableFreePoints = newUserPoints.newAvailableFreePoints - 1
    } 
    this.setState({locations:newLocations, awardablePoints:newUserPoints.newAvailableFreePoints})
}

async subPoints(index){
    let newLocations = this.state.locations
    let newUserPoints = {
        availableFree: this.state.awardablePoints,
        newAvailableFreePoints :this.state.awardablePoints
    }
    let newPoints=0
    if(!newUserPoints.newAvailableFreePoints && newUserPoints.newAvailableFreePoints!=0){
        const points = newUserPoints.availableFree
        newUserPoints["newAvailableFreePoints"]=points
    }
    if( !newLocations[index].newAvailablePoints && newLocations[index].newAvailablePoints !=0){
        newLocations[index]["newAvailablePoints"]= newLocations[index].availablePoints
    }
    if(newLocations[index].newAvailablePoints-1>=0){
        newPoints = newLocations[index].newAvailablePoints-1
        newUserPoints.newAvailableFreePoints = newUserPoints.newAvailableFreePoints + 1
    }
    newLocations[index]["newAvailablePoints"]=newPoints
    this.setState({locations:newLocations, awardablePoints:newUserPoints.newAvailableFreePoints})
}

async getSpendablePoints(){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/spendablePoints`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({spendablePoints:data.totalPoints})
        resolve(data)
    })
}

async getawardablePoints(){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/awardablePoints`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        if(data.totalPoints == 0 ){
            this.setState({spendablePoints:data.totalPoints, movingPoints:0})
        } else {
            this.setState({spendablePoints:data.totalPoints})
        }
        resolve(data)
    })
}

async getUserPoints(){
    try{
        this.getSpendablePoints()
        this.getawardablePoints()
    } catch(error){
        console.log(error)
        return false
    }
}

componentDidMount() {
    getUser().then(async (user)=>{
    const token = await user.getIdToken()
      this.setState({user:user,token:token}, ()=>{
            this.getUserPoints()
            this.getLocation().then(()=>{
                this.loadLocations()
            })
        })

    }).catch((error)=>{
        // window.location.replace("http://localhost:3000/");
        window.location.replace("/home");
    });
    
}

    render() {
        return (
        <>
        
            <main className='p-5  w-full flex flex-col items-center bg-gradient-to-br from-gray-200 to-gray-300 min-h-screen'>
                <div className='max-w-md'>
                    <Map render={this.state.position.latitude && this.state.position.longitude } latitude={this.state.position.latitude} longitude={this.state.position.longitude}/>
                    <button className="flex mb-5 justify-center items-center rounded-md bg-sky-900 text-white font-bold p-3 w-full mb-5'"
                        onClick={()=>{
                            this.getLocationAverage().then(()=>{
                                this.setState({locationConfirmed:false,
                                    newLocationPin:this.state.editing,
                                    position:{
                                    latitude:this.state.latitude,
                                    longitude:this.state.longitude,
                                    altitude:this.state.altitude,
                                    
                                }},
                                ()=>{
                                    this.checkLocation()
                                    
                                })
                            })
                        }}>Get my Location</button>
                        <label className="font-bold text-sm">Geofence Radius in Feet</label>
                        <input className='w-full p-2 rounded-md border-2' name="Radius" type="number" defaultValue={this.state.radius} max={200} min={1} step={1} placeholder="Geofence Radius in feet" 
                        onChange={(e)=>{
                        
                            this.setState({radius:Number(e.target.value)})
                        }}/>  

                        <ul>
                            <li><strong>latitude: </strong>{this.state.position.latitude}</li>
                            <li><strong>longitude: </strong>{this.state.position.longitude}</li>
                        </ul>

                </div>

                
                
                
            </main>
            
           
            
            <Nav />
        </>)
    }
}

// #endregion

export default MyLocations;

