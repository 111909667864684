import React from 'react';
import Form from '../components/form'
import {auth, googleProvider, getUser,db} from '../config/firebase'
import {signInWithPopup,signInWithEmailAndPassword,RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"
import {collection, getDocs, query, where} from "firebase/firestore"
import { Link } from 'react-router-dom';

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
class Login extends React.Component {
constructor(props) {
  super(props);

  this.state = {
    errorMsg:"",
    signInConfirmObj:{},
    OTPCode:"",
    OTP:"",
    hideButton:false
  };
}

// async signInWithGoogle(){
//   try{
//       await signInWithPopup(auth, googleProvider);
//       window.location.replace("/home");
//   } catch (error){
//       console.log(error)
//   }
// }

// async signInWithEmail(data){
//   try{
//       await signInWithEmailAndPassword(auth, data.email, data.password);
//       window.location.replace("/home");
//   } catch (error){
//       console.log(error)
//   }
// }
setUpPhoneRecaptcha(number){
  return new Promise(async (resolve, reject) =>  {
      const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth)
      recaptchaVerifier.render()
      this.setState({hideButton:true})
      resolve(await signInWithPhoneNumber(auth,number,recaptchaVerifier)) 
  })
}

async signInWithPhone(data){
  try{
    this.setState({errorMsg:""})
    this.checkPhoneNumber(data.phone).then((isNew)=>{
      console.log(isNew)
      if(isNew){
        this.setState({errorMsg:"It looks like you need to sign up!"})
      }else{
        this.setUpPhoneRecaptcha(data.phone).then((res)=>{
          this.setState({signInConfirmObj:res,OTPCode:"",OTP:true})
        })
      }

    })
  } catch (error){
      console.log(error)
  }
}

// checkPhoneNumber(number){
//   return new Promise(async (resolve, reject) =>  {
//       const phoneCollection = collection(db,"phoneNumbers")
//       const q = query(phoneCollection,where("number", "==", number))
//       getDocs(q).then((res)=>{
//           const filteredData = res.docs.map((doc)=>({
//               ...doc.data(),
//               id: doc.id
//           }))
//           console.log(filteredData)
//           if(filteredData.length>0){
//               resolve(false)
//           } else {
//               resolve(true)
//           }
          
//       }).catch((error)=>{
//           console.log(error)
//       })
      
//   })
// }

checkPhoneNumber(number){
  return new Promise(async (resolve, reject) =>  {
      let body = {
          number:number,
      }
      let bodyjson = JSON.stringify(body);
      const response = await fetch(`${process.env.REACT_APP_SERVER}/users/checkPhone`, {
      method: "POST",
      body:bodyjson,
      credentials: "include",
      headers: {
          "Content-Type": "application/json"
      }
      
  });
  const data = await response.json();
  resolve(data)  
  })
}

componentDidMount() {
  getUser().then((user)=>{
      console.log(user)
      window.location.replace("/");
  }).catch((error)=>{
      console.log(error)
  });
  
}

  render() {
    let formFeilds = { 
      phone:{
        type:"tel",
        value:"",
        label:"Cell Phone Number*",
        placeHolder:"Cell Phone Number..."
        },
  }
    return <>
    <main className='p-5 w-full flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 to-gray-300 min-h-screen mb-0'>
      
      <div className='w-full max-w-md bg-white rounded-lg shadow-md p-5'>
      <h1 className='text-2xl font-bold text-center mb-6'>Login</h1>
      {/* <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mb-5 hover:bg-sky-700' onClick={()=>{
                this.signInWithGoogle()
            }}>Sign in with Google</button> */}
      {/* <h2 className="text-center">OR</h2> */}
      <Form fields={formFeilds} id="loginForm" callBack={(data)=>{ this.signInWithPhone(data)}}/>
      <p>{this.state.errorMsg}</p>
      
      {
        this.state.OTP?
        <div>
          <label className="font-bold text-sm">Enter Code Received By Text</label>
              <input className='w-full p-2 rounded-md border-2' type="text" maxLength="6" disabled={this.state.OTPCode.length==6} placeholder="Verification Code" onChange={(e)=>{
                  this.setState({OTPCode:e.target.value}, async ()=>{
                      if(this.state.OTPCode.length==6){
                          this.state.signInConfirmObj.confirm(this.state.OTPCode).then((result)=>{
                              window.location.replace("/");
                          }).catch((error)=>{
                              if(error.message.includes("invalid-verification-code")){
                                  e.target.value=""
                                  this.setState({errorMsg:"Verification code was incorrect",OTPCode:""})
                              } 
                          })
                      }
                  })
              }}/>
              {
                  this.state.OTPCode.length==6?
                  <h2>Verifying...</h2>
                  :
                  <></>
              }
        </div>
        :
        <>
          <div id='recaptcha-container'></div>
          {
            this.state.hideButton?
            <></>
            :
            <div>
              <input className='rounded-md bg-sky-900 text-white font-bold p-3 w-full hover:bg-sky-700' type="submit" form="loginForm" value="Login"/>
              <Link to="/signUp">
                  <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mt-5 hover:bg-sky-700'>Sign Up</button>
              </Link>
            </div> 
          }
          
        
        </>
        
      }
      </div>
        
    </main>
      

    </>;
  }
}

export default Login;