import React from 'react';
import Form from '../components/form'
import {auth, googleProvider, getUser,db} from '../config/firebase'
import {createUserWithEmailAndPassword, signInWithPopup,RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"
import {collection, addDoc, getDocs, query, where} from "firebase/firestore"
import { Link } from 'react-router-dom';

let formFeilds = {
    // username:{
    //     type:"text",
    //     value:"",
    //     label:"Username*",
    //     placeHolder:"Username..."
    //     },
    // usernameInfo:{
    //     type:"info",
    //     value:"Your username will be displayed to the public.",
    //     },
    // privacyInfo:{
    //     type:"info",
    //     value:"Your name, phone number, and email address are kept private unless you choose to reveal your info at a later time.",
    //     }, 
    // name:{
    //     type:"text",
    //     value:"",
    //     label:"Name*",
    //     placeHolder:"Name..."
    //     },
    phone:{
        type:"tel",
        value:"",
        label:"Cell Phone Number*",
        placeHolder:"Cell Phone Number..."
        },
    phoneDisclaimer:{
        type:"info",
        value:"*By entering your phone number you agree to recieve text messages. Text messaging rates may apply. By clicking submit you agree to the Terms and Conditions.",
        },
    // email:{
    //     type:"email",
    //     value:"",
    //     label:"Email*",
    //     placeHolder:"Example@Email.com..."
    //     },
    
    error:{
        type:"error",
        value:"",
        },
}

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
class SignUp extends React.Component {
constructor(props) {
    super(props);

    this.state = {
        OTP:false,
        userInfo:{},
        errorMsg:"",
        OTPCode:"",
        signInConfirmObj:{},
        hideButton:false,
        token:"",
        user:null,
        loading:true
    };
}
    // async signUpWithGoogle(){
    //     try{
    //         const userCredential = await signInWithPopup(auth, googleProvider);
    //         await this.userSave(userCredential.user.uid)
    //         window.location.replace("/home");
    //     } catch (error){
    //         console.log(error)
    //     }
    // }
    setUpPhoneRecaptcha(number){
        return new Promise(async (resolve, reject) =>  {
            const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth)
            recaptchaVerifier.render()
            this.setState({hideButton:true})
            resolve(await signInWithPhoneNumber(auth,number,recaptchaVerifier)) 
        })
      } 

    async signUpNewUser(){
        try{
            // const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            // console.log(userCredential)
            // userCredential.user.uid
            // await this.userSave("test")
            // window.location.replace("/home");

        } catch (error){
            console.log(error)
        }
    }

    checkPhoneNumber(number,username){
        return new Promise(async (resolve, reject) =>  {
            let body = {
                number:number,
                username:username,
            }
            let bodyjson = JSON.stringify(body);
            const response = await fetch(`${process.env.REACT_APP_SERVER}/users/checkNew`, {
            method: "POST",
            body:bodyjson,
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
            
        });
        const data = await response.json();
        if(data.error){
            this.setState({errorMsg:data.error})
            reject(false)
        } else {
            resolve(true)
        }    
        })
    }

    async userSave(userId){
        return new Promise(async (resolve, reject) =>  {
            try{
                
                console.log("userSave", userId)
                if(userId){
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    let body = {
                        userId:userId,
                        email:this.state.userInfo.email,
                        phone:this.state.userInfo.phone,
                        name:this.state.userInfo.name,
                        username:this.state.userInfo.username,
                        referralId:urlParams.get('refId'),
                        locationId:urlParams.get('locId')
                    }
                    let bodyjson = JSON.stringify(body);
                    const response = await fetch(`${process.env.REACT_APP_SERVER}/users/new`, {
                        method: "POST",
                        body:bodyjson,
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + this.state.token
                        }
                        
                    });
                    resolve()
                } else{
                    reject()
                }
            } catch (error){
                console.log(error)
                reject()
            }
        })
        
    }

    componentDidMount() {
        getUser().then((user)=>{
            console.log(user)
            window.location.replace("/");
        }).catch((error)=>{
            console.log(error)
            this.setState({loading:false}) 
        });
           
    }



    
    render() {
        return (<>
        <main className='p-5 w-full flex flex-col items-center bg-gradient-to-br from-gray-200 to-gray-300 min-h-screen mb-0'>
            
            {/* <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mb-5 hover:bg-sky-700' onClick={()=>{
                
            }}>Sign Up with Google</button> */}
            
            {
                !this.state.loading?
                    <div>
                    {
                        !this.state.OTP?
                        <div className='w-full max-w-md bg-white rounded-lg shadow-md p-5 mt-10'>
                            
                            
                            <h1 className='text-4xl font-bold text-center mb-3'>Sign Up</h1>
                            <h1 className='text-xl font-bold text-green-700 text-center mb-2'>Free to use!</h1>
                            <h1 className='text-lg font-bold text-center mb-6'>Start Exploring Your Community, Earning Points and Winning Prizes.</h1>
                            
                            
                            <Form id="signUpForm" fields={formFeilds} callBack={(data)=>{
                                console.log(data)
                                
                                this.setState({errorMsg:""})
                                if(!data.phone || !Number(data.phone)){
                                    this.setState({errorMsg:"Please Enter a valid phone number"})
                                    return
                                }
                                // if(!data.name){
                                //     this.setState({errorMsg:"Please Enter a your name"})
                                //     return
                                // }
                                // if(!data.email){
                                //     this.setState({errorMsg:"Please Enter a your email"})
                                //     return
                                // }
                                // if(!data.username){
                                //     this.setState({errorMsg:"Please Enter a username"})
                                //     return
                                // }
                                this.checkPhoneNumber(data.phone, data.username).then((isNew)=>{
                                    if(isNew){
                                        this.setState({userInfo:data,},()=>{
                                                this.setUpPhoneRecaptcha(data.phone).then((res)=>{
                                                    this.setState({signInConfirmObj:res,OTPCode:"",OTP:true})
                                                })
                                            })
                                    }
                                    // 
                                })
                                
                                
                                }}/>
                                <div id='recaptcha-container'></div>
                                <p>{this.state.errorMsg}</p>
                                {
                                    this.state.hideButton?
                                    <></>
                                    :
                                    <>
                                        <input className='rounded-md bg-sky-900 text-white font-bold p-3 w-full hover:bg-sky-700' type="submit" form="signUpForm" value="Sign Up"/>
                                        <Link className=" w-full cursor-pointer text-center mb-3 " to="/terms" target="_blank">
                                            <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mt-5 hover:bg-sky-700'>Terms and Conditions</button>
                                        </Link>
                                    </>
                                }
                                {/* <p className='text-sm  text-center'>By clicking submit you agree to the Terms and Conditions.</p> */}
                                {/* <img src="../../images/pindasherWinning.jpg" alt="Explore Your Community" className="w-full mb-4 rounded-lg"/> */}
                                
                        </div>
                        :
                        <div className='p-2 w-full max-w-md bg-white shadow-md rounded-md mt-10'>
                            <div className="mb-3">
                                <label className="font-bold text-sm">Enter Code Received By Text</label>
                                <input className='w-full p-2 rounded-md border-2' type="text" maxLength="6" disabled={this.state.OTPCode.length==6} placeholder="Verification Code" onChange={(e)=>{
                                    this.setState({OTPCode:e.target.value}, async ()=>{
                                        if(this.state.OTPCode.length==6){
                                            this.state.signInConfirmObj.confirm(this.state.OTPCode).then((result)=>{
                                                console.log("result.user.uid",result.user.uid)
                                                getUser().then(async (user)=>{
                                                    const token = await user.getIdToken()
                                                    this.setState({user:user,token:token},()=>{
                                                        this.userSave(result.user.uid).then(()=>{
                                                            window.location.replace("/");
                                                        })
                                                    })
                                                
                                                }).catch((error)=>{
                                                    window.location.replace("/home");
                                                });
                                                
                                            }).catch((error)=>{
                                                if(error.message.includes("invalid-verification-code")){
                                                    e.target.value=""
                                                    this.setState({errorMsg:"Verification code was incorrect",OTPCode:""})
                                                }
                                                
                                            })
                                        }
                                    })
                                }}/>
                            </div>
                            {
                                this.state.OTPCode.length==6?
                                <h2>Verifying...</h2>
                                :
                                <></>
                            }
                            
                            <p>{this.state.errorMsg}</p>
                            
                        </div>

                    }

                    </div>
                :
                <h1 className='text-center w-full font-bold text-3xl'>
                     Loading...    
                </h1>

            }
            
            
        </main>
    </>)
            
    }
        
}


// #endregion

export default SignUp;


