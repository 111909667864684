import React from 'react';
import { getUser } from '../config/firebase';
import Nav from '../components/nav';

class AdminUsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      users: [],
      token: "",
      errorMsg: "",
      totals: {
        locations: 0,
        totalAwardable: 0,
        awardableAssigned: 0,
        totalEarned: 0,
        unspent: 0
      }
    };
  }

  fetchAllUsers = async () => {
    return new Promise(async (resolve, reject) =>  {
      console.log("fetchAllUsers")
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/users/admin/all`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.token
          }
        });
        const usersData = await response.json();
        if (response.ok) {
          this.setState({ users: usersData },()=>{
            this.calculateTotals();
            resolve()
          });
        } else {
          reject('Failed to fetch users.');
          if(response.status == 401){
            window.location.replace("/");
          } 
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        this.setState({ errorMsg: "Error fetching user data. Please try again." },()=>{
          reject()
        });
      }
    })
  }

  calculateTotals = () => {
    const totals = this.state.users.reduce((acc, user) => {
      acc.locations += user.locations || 0;
      acc.totalAwardable += user.totalAwardable || 0;
      acc.awardableAssigned += user.awardableAssigned || 0;
      acc.totalEarned += user.totalEarned || 0;
      acc.unspent += user.unspent || 0;
      return acc;
    }, { locations: 0, totalAwardable: 0, awardableAssigned: 0, totalEarned: 0, unspent: 0 });
    this.setState({ totals });
  }

  componentDidMount() {
    getUser().then(async (user) => {
      const token = await user.getIdToken();
     
      
        this.setState({ user: user, token: token }, async ()=>{
          await this.fetchAllUsers()
        });
    
    }).catch((error) => {
      console.log("Error initializing user data: ", error);
      window.location.replace("/login");
    });
  }

  render() {
    const { users, errorMsg, totals } = this.state;
    return (
      <>
        <Nav />
        <main className='p-5 w-full flex flex-col items-center bg-gradient-to-br from-gray-200 to-gray-300 min-h-screen'>

          <div className='w-full max-w-xl bg-white rounded-lg shadow-md p-5 mt-5 overflow-auto'>
            <h1 className='text-center font-bold text-3xl mb-5'>User Administration</h1>
            <table className='table-auto w-full '>
              <thead>
                <tr className='bg-gray-200'>
                  <th className='px-4 py-2'>Name</th>
                  <th className='px-4 py-2'>Username</th>
                  <th className='px-4 py-2'>Email</th>
                  <th className='px-4 py-2'>Phone</th>
                  <th className='px-4 py-2'>Last Active</th>
                  <th className='px-4 py-2'>Locations</th>
                  <th className='px-4 py-2'>Awardable Points</th>
                  <th className='px-4 py-2'>Points Assigned</th>
                  <th className='px-4 py-2'>Points Earned</th>
                  <th className='px-4 py-2'>Points Unspent</th>
                  <th className='px-4 py-2'>Admin Level</th>
                </tr>
              </thead>
              {this.state.users.length > 0 ? (
                <tbody>
                  <tr className='bg-gray-300'>
                    <td className='border px-4 py-2'><strong>Total Users</strong></td>
                    <td className='border px-4 py-2'><strong>{this.state.users.length}</strong></td>
                    <td className='border px-4 py-2' colSpan="3"><strong>Totals:</strong></td>
                    <td className='border px-4 py-2'><strong>{totals.locations}</strong></td>
                    <td className='border px-4 py-2'><strong>{totals.totalAwardable}</strong></td>
                    <td className='border px-4 py-2'><strong>{totals.awardableAssigned}</strong></td>
                    <td className='border px-4 py-2'><strong>{totals.totalEarned}</strong></td>
                    <td className='border px-4 py-2'><strong>{totals.unspent}</strong></td>
                    <td className='border px-4 py-2'></td> {/* Empty cell for admin level */}
                  </tr>
                  {users.map((user, index) => (
                    <tr key={index} className={`bg-white ${index % 2 ? 'bg-gray-100' : ''}`}>
                      <td className='border px-4 py-2'>{user.name}</td>
                      <td className='border px-4 py-2'>{user.username}</td>
                      <td className='border px-4 py-2'>{user.email}</td>
                      <td className='border px-4 py-2'>{user.phone}</td>
                      <td className='border px-4 py-2'>{user.lastActive}</td>
                      <td className='border px-4 py-2'>{user.locations}</td>
                      <td className='border px-4 py-2'>{user.totalAwardable}</td>
                      <td className='border px-4 py-2'>{user.awardableAssigned}</td>
                      <td className='border px-4 py-2'>{user.totalEarned}</td>
                      <td className='border px-4 py-2'>{user.unspent}</td>
                      <td className='border px-4 py-2'>{user.adminLvl}</td>
                    </tr>
                  ))}
                  
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="11" className='text-center py-4'>No user data available.</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </main>

      </>
    );
  }
}

export default AdminUsersPage;
