import React from 'react';
import {getUser} from '../config/firebase'
import Nav from '../components/nav'
import { Link } from 'react-router-dom';
import Form from '../components/form'



class Home extends React.Component {
constructor(props) {
  super(props);

  this.state = {
    user:{},
    editUser:false,
    token:"",
    formFeilds : null,
    errorMsg:""
  };
}


async updateUserInfo(data){
  return new Promise(async (resolve, reject) =>  {
      try{
          console.log("data",data, this.state.user)
          if(this.state.user.uid && data.email && data.name && data.username){
              let body = {
                  userId:this.state.user.uid,
                  email:data.email,
                  name:data.name,
                  username:data.username,
              }
              let bodyjson = JSON.stringify(body);
              const response = await fetch(`${process.env.REACT_APP_SERVER}/users/update`, {
                  method: "PUT",
                  body:bodyjson,
                  credentials: "include",
                  headers: {
                      "Content-Type": "application/json",
                      "Authorization": "Bearer " + this.state.token
                  }
                  
              });
              console.log("response",response)
              window.location.reload()
              resolve()
          } else{
              reject("Please provide username, name, and email.")
          }
      } catch (error){
          console.log(error)
          reject()
      }
  })
  
}



componentDidMount() {
  this.setState({loading:true})
  getUser().then(async(user)=>{
    const token = await user.getIdToken()
        const dbUserRes = await fetch(`${process.env.REACT_APP_SERVER}/users/self`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
        const dbUser = await dbUserRes.json();
        let  formFeilds = {
          username:{
            type:"text",
            value:dbUser.username,
            label:"Username*",
            placeHolder:"Username..."
            },
        usernameInfo:{
            type:"info",
            value:"*Your username will be displayed to the public.",
            },
        
        name:{
            type:"text",
            value:dbUser.name,
            label:"Name**",
            placeHolder:"Name..."
            },
    
        email:{
            type:"email",
            value:dbUser.email,
            label:"Email**",
            placeHolder:"Example@Email.com..."
            },
        emailInfo:{
            type:"info",
            value:"By entering your email you agree to allow us to email you.",
            },
        privacyInfo:{
            type:"info",
            value:"**Your name, phone number, and email address are kept private unless you choose to reveal your info at a later time.",
            },
        
        error:{
            type:"error",
            value:"",
            },
        }
      this.setState({user:dbUser, formFeilds:formFeilds, token:token,loading:false},()=>{
       
      })

  }).catch((error)=>{
      window.location.replace("/home");
  });
  
}

  render() {
    return  (
      <>
        <main className='p-5 w-full flex flex-col items-center bg-gradient-to-br from-gray-200 to-gray-300 min-h-screen mb-20'>
            {
              !this.state.editUser?
              <div className='w-full max-w-md bg-white rounded-lg shadow-md p-5 mt-10'>
              <h1 className='text-center font-bold text-3xl mb-5'>Home</h1>
              <h2 className='text-center font-bold text-xl mb-5'>Welcome{this.state.user.username?" "+this.state.user.username:""}!</h2>
              <p>Thank you for using PinDasher! We really appreciate your support and hope you enjoy the app's experience.</p>
              <p className='mt-3'>If you notice any bugs or have suggestions please email us at <a className='cursor-pointer text-sky-500' href = "mailto: pindasher.com@gmail.com">pindasher.com@gmail.com</a> we would love your feedback.</p>
              <p className='mt-3'>Please read our Terms and Conditions below.</p>
              {
                this.state.user.adminLvl >=4?
                <Link className=" w-full cursor-pointer text-center mb-3 " to="/admin/users" target="_blank">
                    <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mt-5  hover:bg-sky-700'>View Users</button>
                </Link>
              :
              null
              }
              {
                !this.state.loading?
                  <button disabled={this.state.user.userId ==""} className={`${this.state.user.email && this.state.user.name && this.state.user.username?"bg-sky-900 hover:bg-sky-700":"bg-red-700 hover:bg-red-900"} rounded-md  text-white font-bold p-3 w-full mt-5  `} onClick={()=>{
                                  this.setState({editUser:true})
                                }}>{this.state.user.email && this.state.user.name && this.state.user.username? "Update User Info": "Please Add Your Info"}</button>
                  :
                  <></>
              }
              
              <Link className=" w-full cursor-pointer text-center mb-3 " to="/terms" target="_blank">
                  <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mt-5  hover:bg-sky-700'>Terms and Conditions</button>
              </Link>
              

            </div>
            :
            <div className='w-full max-w-md bg-white rounded-lg shadow-md p-5 mt-10'>
              <div className='flex justify-between'>
                  <div className="w-1/6 cursor-pointer" onClick={()=>{
                      this.setState({editUser:false})
                  }}>
                      <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/></svg>
                  </div>
                  <h1 className='text-center w-5/6 font-bold text-3xl mb-5 poin'>Edit User</h1>
              </div>
              <Form id="updateForm" fields={this.state.formFeilds} callBack={async (data)=>{
                                console.log(data)
                                
                                this.setState({errorMsg:""})
                                if(!data.name){
                                    this.setState({errorMsg:"Please Enter a your name"})
                                    return
                                }
                                if(!data.email){
                                    this.setState({errorMsg:"Please Enter a your email"})
                                    return
                                }
                                if(!data.username){
                                    this.setState({errorMsg:"Please Enter a username"})
                                    return
                                }
                                try {
                                  await this.updateUserInfo(data)
                                } catch (error) {
                                  console.log("error",error)
                                  this.setState({errorMsg:error})
                                }
                                
                                }}/>
              <p>{this.state.errorMsg}</p>
              <input className='rounded-md bg-sky-900 text-white font-bold p-3 w-full hover:bg-sky-700' type="submit" form="updateForm" value="Save"/>

            </div>}
        </main>
        <Nav />
      </>
    )
  }
}

export default Home;