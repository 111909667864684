import React from 'react';
import { Link } from 'react-router-dom';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertising: false
    };
  }

  toggleAdvertising = () => {
    this.setState(prevState => ({
      advertising: !prevState.advertising
    }));
  }

  render() {
    return (
      <>
        <div className="bg-gradient-to-br from-sky-500 to-blue-600 min-h-screen flex flex-col items-center p-4">
          <div className="w-full max-w-md bg-white rounded-lg shadow-md p-5 mt-10">
            <h1 className="text-2xl font-bold text-center mb-6">Welcome to PinDasher!</h1>
            <p className="text-sm text-gray-600 text-center mb-6">
              Embark on an exciting adventure in your community! Uncover hidden gems, earn points, and win fabulous prizes with PinDasher.
            </p>
         
            <p className="text-sm text-gray-600 mb-6">Welcome to Pindasher, your ultimate adventure! Explore the city, uncover hidden gems, and earn points on exciting scavenger hunts. Rack up those points to enter our exclusive raffles for amazing prizes. Ready to dash through the city and pin your way to victory? Let's get hunting!  </p>

            <h2  className="text-lg font-semibold text-gray-800 mb-4 text-center text-red-700">Free To Explore and Earn Points!</h2>
              <Link to="/signUp">
                <button className="bg-sky-900 hover:bg-sky-700 text-white font-bold py-2 px-4 w-full rounded mb-4">
                  Start Exploring
                </button>
              </Link>
            
            <img src="../../images/grouponphones.jpg" alt="Explore Your Community" className="w-full mb-4 rounded-lg"/>
            <div>
              <h2 className="text-lg font-semibold text-gray-800 mb-4">Explore Your Area</h2>
              <p className="text-sm text-gray-600 mb-4">
                Visit participating location to collect points. These points can be exchanged for entries to win prizes like gift cards, car washes, and more!
              </p>
              
              <div className='flex' style={{overflowX:"scroll", maxWidth:"400px"}}>
                <img width={250} src="../../images/pindasherExplore.png" alt="Explore Your Community" className="mr-2 rounded-lg border-8 border-sky-900"/>
                <img width={250} src="../../images/pindasherlocation1.png" alt="Explore Your Community" className=" mr-2 rounded-lg  border-8 border-sky-900"/>
                <img width={250} src="../../images/pindasherlocation2.png" alt="Explore Your Community" className=" rounded-lg border-8 border-sky-900"/>
                
                
              </div>
              
            </div>
            <hr className='m-5'></hr>
            <div>
                <h2 className="text-lg font-semibold text-gray-800 mb-4">Advertising with PinDasher</h2>
                <ul className="list-disc pl-5 mb-4 text-sm">
                  <li>Increase foot traffic to your store or sponsored location</li>
                  <li>Flexible Pay-per-Visit advertising</li>
                  <li>Attract new customers with rewards</li>
                  <li>Gain visibility in your community</li>
                  <li>Increase virtual traffic to your website</li>
                  <li>Use points to incentivize visits and purchases</li>
                </ul>
                {/* <img src="your-advertising-image-url.jpg" alt="Advertising Opportunities" className="w-full mb-4 rounded"/> */}
                <h2  className="text-lg font-semibold text-gray-800 mb-4 text-center text-red-700">Advertising Credit as Low as $0.67 Per Visit</h2>
                <Link to="/signUp">
                  <button className="bg-sky-900 hover:bg-sky-700 text-white font-bold py-2 px-4 w-full rounded mb-4">
                    Start Sponsoring
                  </button>
                </Link>
                
            </div>
            
          </div>
        </div>
      </>
    );
  }
}

export default LandingPage;
