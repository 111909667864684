import React from 'react';
import {getUser} from '../config/firebase'
import Form from '../components/form'
import Nav from '../components/nav'
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';


class Raffle extends React.Component {
constructor(props) {
  super(props);

  this.state = {
    errorMsg:"",
    user:{},
    token:null,
    sponser:true,
    page:"",
    phone:"",
    movingPoints:0,
    selectedPrize:{},
    prizes:[],
    winners:[],
    events:[],
    spendablePoints:0,
    awardablePoints:0,
    formFeilds:{}
  };
}

async getSpendablePoints(){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/spendablePoints`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
      
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        if(data.totalPoints == 0 ){
            this.setState({spendablePoints:data.totalPoints, movingPoints:0})
        } else {
            this.setState({spendablePoints:data.totalPoints})
        }
       
        resolve(data)
    })
}

async getawardablePoints(){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/awardablePoints`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
       
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({awardablePoints:data.totalPoints})
        resolve(data)
    })
}

async getWinners(){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/winners`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token

            }
        });
        const data = await response.json();
       
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({winners:data})
        resolve(data)
    })
}

async getPrizes(){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/prizes`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({prizes:data})
        resolve(data)
    })
}

async createPrize(incomingData){
    return new Promise(async (resolve, reject)  => {
        if(!incomingData.title){
            this.setState({errorMsg:"You must provide title"})
        }
        if(!incomingData.dollarValue){
            this.setState({errorMsg:"You must provide Dollar Value"})
        }
        let body = {
            title:incomingData.title,
            description:incomingData.description,
            dollarValue:incomingData.dollarValue,
            imageUrl:incomingData.imageUrl
        }
        let bodyjson = JSON.stringify(body);
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/prizes`, {
            method: "POST",
            body:bodyjson,
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({prizes:data,page:""})
        resolve(data)
    })
}

async enterRaffle(){
    return new Promise(async (resolve, reject)  => {
        if(this.state.movingPoints<=0){
            this.setState({errorMsg:"Must use at least 1 point"})
            reject()
        }
        let body = {
            prizeId:this.state.selectedPrize.prizeId,
            numberPointsEntered: this.state.movingPoints
    
        }
        let bodyjson = JSON.stringify(body);
        const response = await fetch(`${process.env.REACT_APP_SERVER}/raffle/enterRaffle`, {
            method: "POST",
            body:bodyjson,
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
        
        if(data.winner.awardedPhone){
            this.setState({winner:data.winner,page:"winner", selectedPrize:data.prize, movingPoints:0})
        }else{
            this.getPrizes()
            this.setState({page:""})
        }
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        resolve(data)
    })
}

async getEvents(){
    return new Promise(async (resolve, reject)  => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/events`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();
      
        this.setState({events:data})
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({events:data})
        resolve()
    })
}

async updateUserInfo(data){
    return new Promise(async (resolve, reject) =>  {
        try{
            console.log("data",data, this.state.user)
            if(this.state.user.uid && data.email && data.name && data.username){
                let body = {
                    userId:this.state.user.uid,
                    email:data.email,
                    name:data.name,
                    username:data.username,
                }
                let bodyjson = JSON.stringify(body);
                const response = await fetch(`${process.env.REACT_APP_SERVER}/users/update`, {
                    method: "PUT",
                    body:bodyjson,
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.state.token
                    }
                    
                });
                console.log("response",response)
                getUser().then(async (user)=>{
                    const token = await user.getIdToken()
                    const dbUserRes = await fetch(`${process.env.REACT_APP_SERVER}/users/self`, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token
                        }
                    });
                    const dbUser = await dbUserRes.json();
                    this.setState({user:dbUser})
                    resolve()
                })
                
            } else{
                reject("Please provide username, name, and email.")
            }
        } catch (error){
            console.log(error)
            reject()
        }
    })
    
  }

async createEvent(incomingData){
    return new Promise(async (resolve, reject)  => {
        if(!incomingData.title){
            this.setState({errorMsg:"You must provide title"})
        }
        if(!incomingData.freeEntry){
            this.setState({errorMsg:"You must provide a way to enter for free."})
        }
        let body = {
            title:incomingData.title,
            description:incomingData.description,
            freeEntry:incomingData.freeEntry,
            otherEntry:incomingData.otherEntry,
            imageUrl:incomingData.imageUrl,
            linkUrl:incomingData.linkUrl,
        }
        let bodyjson = JSON.stringify(body);
        const response = await fetch(`${process.env.REACT_APP_SERVER}/events`, {
            method: "POST",
            body:bodyjson,
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.state.token
            }
        });
        const data = await response.json();

        this.setState({page:"events",events:data})
     
        if(data.error){
            this.setState({errorMsg:data.error})
            reject()
        }
        this.setState({events:data})
        resolve(data)
    })
}

componentDidMount() {
  this.setState({loading:true})
  
  getUser().then(async (user)=>{
        const token = await user.getIdToken()
        const dbUserRes = await fetch(`${process.env.REACT_APP_SERVER}/users/self`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
        const dbUser = await dbUserRes.json();
        let  formFeilds = {
            username:{
                type:"text",
                value:dbUser.username,
                label:"Username*",
                placeHolder:"Username..."
                },
            usernameInfo:{
                type:"info",
                value:"*Your username will be displayed to the public.",
                },
            
            name:{
                type:"text",
                value:dbUser.name,
                label:"Name**",
                placeHolder:"Name..."
                },
        
            email:{
                type:"email",
                value:dbUser.email,
                label:"Email**",
                placeHolder:"Example@Email.com..."
                },
            emailInfo:{
                type:"info",
                value:"By entering your email you agree to allow us to email you.",
                },
            privacyInfo:{
                type:"info",
                value:"**Your name, phone number, and email address are kept private unless you choose to reveal your info at a later time.",
                },
            
            error:{
                type:"error",
                value:"",
                },
          }
      this.setState({user:dbUser, formFeilds:formFeilds,token:token},()=>{
        this.getPrizes()
        this.getSpendablePoints()
        this.getawardablePoints()
         

      })

  }).catch((error)=>{
      window.location.replace("/");
  });
  
}

  render() {
    let newPrizeFeilds = { 
        title:{
            type:"text",
            value:"",
            label:"Title*",
            placeHolder:""
        },
        description:{
            type:"textarea",
            value:"",
            label:"Description",
            placeHolder:""
        },
        dollarValue:{
            type:"number",
            value:"",
            label:"Dollar Value*",
            placeHolder:""
        },
        imageUrl:{
            type:"text",
            value:"",
            label:"Image Url",
            placeHolder:""
        },
        Submit:{
            type:"submit",
            value:true,
            label:"New Prize",
            placeHolder:""
        },
    }

    let newEventFeilds = {
        title:{
            type:"text",
            value:"",
            label:"Title*",
            placeHolder:""
        },
        description:{
            type:"textarea",
            value:"",
            label:"Description",
            placeHolder:""
        },
        freeEntry:{
            type:"textarea",
            value:"",
            label:"Ways to enter for free*",
            placeHolder:""
        },
        otherEntry:{
            type:"textarea",
            value:"",
            label:"Other ways to enter",
            placeHolder:""
        },
        linkUrl:{
            type:"text",
            value:"",
            label:"URL for More Info",
            placeHolder:""
        },
        
        Submit:{
            type:"submit",
            value:true,
            label:"New Event",
            placeHolder:""
        },
    }
    return  (
      <>
        <main className='p-5 w-full flex flex-col items-center bg-gradient-to-br from-gray-200 to-gray-300 min-h-screen  mb-20'>
        <div className='flex bg-white rounded-md w-full max-w-md'>
                <button to="/explore" className="flex flex-col items-center justify-between cursor-pointer hover:bg-gray-100 w-1/2 p-2"
                    onClick={()=>{
                        this.getSpendablePoints()
                        this.getPrizes()
                        this.setState({page:""})
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" idth="36" height="36" viewBox="0 0 512 512">
                        <path d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z"/></svg>
                   
                    <p className="text-sm">Prizes</p>
                </button>
            

                <button to="/explore" className="flex flex-col items-center justify-between cursor-pointer hover:bg-gray-100 w-1/2 p-2"
                    onClick={()=>{
                        this.getWinners()
                        this.setState({page:"winners"})
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="36" height="36">
                        <path d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"/>
                    </svg>
                    <p className="text-sm">Winners</p>
                </button>

                {/* <button to="/explore" className="flex flex-col items-center justify-between cursor-pointer hover:bg-gray-100 w-1/3 p-2"
                    onClick={()=>{
                        this.getEvents()
                        this.setState({page:"events"})
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 640 512"><path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"/></svg>
                    
                    <p className="text-sm">Events</p>
                </button> */}

            </div>

           
            

          
          
          {
            this.state.page==""?
            <div className=' w-full max-w-md'>
                <h1 className='text-center font-bold text-3xl mt-2 mb-2'>Prizes</h1>
                {
                    this.state.page!="buy" && this.state.user.adminLvl > 3 ?
                    <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mt-2  hover:bg-sky-700' 
                    onClick={()=>{
                        this.setState({page:"newPrize"})
                    }}>New Prize</button>
                    :
                    <></>
                }
                <div  className='bg-white p-3 rounded-md mt-2 mb-5 border-solid border border-gray-600 '>
                    <p className='text-xs font-bold text-center'>Your Points</p>
                    <h2 className='text-2xl font-bold text-center'>{this.state.spendablePoints}</h2>
                </div>
                <p className="text-center">Winners are chosen once all entries for that prize are entered. The more entries you enter the higher your chances to win.</p>
                {
                    this.state.prizes.map((prize,index)=>{
                        return(
                            <div key={index} className='bg-white p-6 rounded-md mt-2 mb-5 shadow'>
                                {
                                        prize.imageUrl?
                                        <div className='bg-gray-300 h-48 w-full rounded-md mb-4 mt-4' style={{backgroundImage:`url('${prize.imageUrl}')`,backgroundRepeat:"no-repeat",backgroundPosition:"center", backgroundSize:"cover"}}></div>
                                        :
                                        <></>
                                    }
                                <h2 className='text-2xl font-bold'>{prize.title}</h2>
                                <p>{prize.description}</p>
                                <div className="w-full h-4 mb-4 bg-gray-200 rounded-full dark:bg-gray-700">
                                    <div className="h-4 bg-blue-600 rounded-full dark:bg-blue-500" style={{width: `${Math.floor(((prize.entriesNeeded - prize.remainingEntries)/prize.entriesNeeded)*100)}%`}}></div>
                                </div>
                                <div className='flex justify-around mt-4'>
                                    <div className='text-center'>
                                        <p className='text-xs font-bold'>Remaining Entries</p>
                                        <h2 className='text-xl font-bold'>{prize.remainingEntries}</h2>
                                    </div>
                                    <div className='text-center'>
                                        <p className='text-xs font-bold'>Total Entries</p>
                                        <h2 className='text-xl font-bold'>{prize.entriesNeeded}</h2>
                                    </div>
                                    
                                    <button className='rounded-md bg-sky-900 text-white font-bold p-3  mt-2  hover:bg-sky-700' 
                                        onClick={()=>{
                                            this.getSpendablePoints()
                                            this.setState({page:this.state.user.email && this.state.user.username?"enter":"user info",selectedPrize:prize, movingPoints:1})
                                        }}>Enter to Win</button>
                                </div>
                                
                            </div>
                        )
                    })
                }
            </div>
            :
            <></>
          }

          {
            this.state.page == "user info"?
            <div className='w-full max-w-md bg-white rounded-lg shadow-md p-5 mt-10'>
              <div className='flex justify-between'>
                  <div className="w-1/6 cursor-pointer" onClick={()=>{
                      this.setState({page:""})
                  }}>
                      <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/></svg>
                  </div>
                  <h1 className='text-center w-5/6 font-bold text-3xl mb-5 poin'>Edit User</h1>
              </div>
              <p className='text-red-700 text-xl text-center'>Please add your info to enter to win a prize.</p>
              <Form id="updateForm" fields={this.state.formFeilds} callBack={async (data)=>{
                                console.log(data)
                                
                                this.setState({errorMsg:""})
                                if(!data.name){
                                    this.setState({errorMsg:"Please Enter a your name"})
                                    return
                                }
                                if(!data.email){
                                    this.setState({errorMsg:"Please Enter a your email"})
                                    return
                                }
                                if(!data.username){
                                    this.setState({errorMsg:"Please Enter a username"})
                                    return
                                }
                                try {
                                  await this.updateUserInfo(data)
                                  this.setState({page:"enter"})
                                } catch (error) {
                                  console.log("error",error)
                                  this.setState({errorMsg:error})
                                }
                                
                                }}/>
              <p>{this.state.errorMsg}</p>
              <input className='rounded-md bg-sky-900 text-white font-bold p-3 w-full hover:bg-sky-700' type="submit" form="updateForm" value="Save"/>

            </div>
            :
            <></>
          }

        {
            this.state.page=="newPrize"?
            <div className=' w-full max-w-md'>
                <h1 className='text-center font-bold text-3xl mb-5'>New Prize</h1>
                <div className='bg-white p-6 rounded-md'>
                    <Form fields={newPrizeFeilds} id="loginForm" callBack={(data)=>{ this.createPrize(data)}}/>
                    {
                    this.state.errorMsg?
                    <p className='text-red-800'>{this.state.errorMsg}</p>
                    :
                    <></>
                    }
                    
                    <button className='rounded-md bg-gray-600 text-white font-bold p-3 w-full mt-5  hover:bg-gray-400' 
                    onClick={()=>{
                        this.setState({page:""})
                    }}>Cancel</button>
                </div>
            </div>
            :
            <></>
          }
          
          {
            this.state.page=="enter"?
            <div className=' w-full max-w-md'>
                <h1 className='text-center font-bold text-3xl mb-5'>Enter To Win</h1>
                <div className='bg-white p-6 rounded-md'>
                    <h2 className='text-2xl font-bold text-center'>{this.state.selectedPrize.title}</h2>
                    <div className='flex justify-around items-center m-4'>
                        <button className='rounded-md bg-sky-900 text-white font-bold p-3 mt-2  hover:bg-sky-700' 
                        onClick={()=>{
                            let newTotal = this.state.movingPoints-1
                            if(newTotal<=0){
                                newTotal=0
                            }
                            this.setState({movingPoints:newTotal})
                        }}>-</button>
                        <h2 className='text-xl font-bold'>{this.state.movingPoints}</h2>
                        <button className='rounded-md bg-sky-900 text-white font-bold p-3 mt-2  hover:bg-sky-700' 
                        onClick={()=>{
                            let newTotal = this.state.movingPoints+1
                            if(newTotal>this.state.spendablePoints){
                                newTotal=this.state.spendablePoints
                            }
                            this.setState({movingPoints:newTotal})
                        }}>+</button>

                    </div>
                    <div className='flex justify-around'>
                        <div className='text-center'>
                            <p className='text-xs font-bold'>Remaining</p>
                            <h2 className='text-xl font-bold'>{this.state.selectedPrize.remainingEntries}</h2>
                        </div>
                        <div className='text-center'>
                            <p className='text-xs font-bold'>Total Entries</p>
                            <h2 className='text-xl font-bold'>{this.state.selectedPrize.entriesNeeded}</h2>
                        </div>
                    </div>

                    <div className='text-center'>
                        <p className='text-xs font-bold'>Your Points</p>
                        <h2 className='text-xl font-bold'>{this.state.spendablePoints}</h2>
                    </div>
                    
                    {
                    this.state.errorMsg?
                    <p className='text-red-800'>{this.state.errorMsg}</p>
                    :
                    <></>
                    }
                    <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mt-2  hover:bg-sky-700' 
                    onClick={async ()=>{
                        await this.enterRaffle()
                        this.getSpendablePoints()
                    }}>Enter To Win!</button>
                    <button className='rounded-md bg-gray-600 text-white font-bold p-3 w-full mt-5  hover:bg-gray-400' 
                    onClick={()=>{
                        this.setState({page:"", movingPoints:0})
                    }}>Cancel</button>
                </div>
            </div>
            :
            <></>
          }
          
          {
            this.state.page=="winner"?
            <div className=' w-full max-w-md'>
                <h1 className='text-center font-bold text-3xl mb-5'>We have selected a Winner!</h1>
                <div className='bg-white p-6 rounded-md mt-2 mb-5'>
                    {this.state.winner.username==this.state.user.username?
                    <div>
                        <h2 className='text-3xl font-bold text-center'>Congratulations!</h2> 
                        <h2 className='text-2xl font-bold text-center'>You {this.state.winner.username} Have Won The</h2> 
                        <h2 className='text-2xl font-bold text-center'>{this.state.selectedPrize?this.state.selectedPrize.title:""}</h2>
                    </div>

                    :
                        <div>
                            <h2 className='text-2xl font-bold text-center'>Better luck next time!</h2> 
                            <h2 className='text-2xl font-bold text-center'>Congratulations to {this.state.winner.username}. They have Won The</h2> 
                            <h2 className='text-2xl font-bold text-center'>{this.state.selectedPrize?this.state.selectedPrize.title:""}</h2>
                        </div>
                    }
                    
                    
                    <p></p>
                    <button className='rounded-md bg-sky-900 text-white font-bold p-3  mt-2 w-full hover:bg-sky-700' 
                            onClick={()=>{
                                this.getPrizes()
                                this.setState({page:"",selectedPrize:{}})
                            }}>Close</button>
                    
                </div>
            </div>
            :
            <></>
          }
          
          {
            this.state.page=="events"?
            <div className=' w-full max-w-md'>
                <h1 className='text-center font-bold text-3xl mb-5'>Events</h1>
                {
                    this.state.page!="buy"?
                    <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full mt-2  hover:bg-sky-700' 
                    onClick={()=>{
                        this.setState({page:"newEvent"})
                    }}>New Event</button>
                    :
                    <></>
                }
                {
                    this.state.events.map((event,index)=>{
                        return(
                            <div key={index} className='bg-white p-6 rounded-md mt-2 mb-5 shadow'>
                                <h2 className='text-2xl font-bold'>{event.title}</h2>
                                <p className='text-xs font-bold'>Event Description:</p>
                                <p>{event.description}</p>
                                <p className='text-xs font-bold mt-4'>Ways to enter for free:</p>
                                <p>{event.freeEntry}</p>
                                <p className='text-xs font-bold mt-4'>Other ways to enter:</p>
                                <p className='mb-5'>{event.otherEntry}</p>
                                <Link className=" w-full cursor-pointer text-center mb-3 " to={event.linkUrl} target="_blank">
                                    <button className='rounded-md bg-sky-900 text-white font-bold p-3 w-full  hover:bg-sky-700' 
                                    >More Info</button>
                                </Link>
                                
                            </div>
                        )
                    })
                }
            </div>
            :
            <></>
          }

            {
            this.state.page=="newEvent"?
            <div className=' w-full max-w-md'>
                <h1 className='text-center font-bold text-3xl mb-5'>New Prize</h1>
                <div className='bg-white p-6 rounded-md'>
                    <Form fields={newEventFeilds} id="newEventForm" callBack={(data)=>{ this.createEvent(data)}}/>
                    {
                    this.state.errorMsg?
                    <p className='text-red-800'>{this.state.errorMsg}</p>
                    :
                    <></>
                    }
                    
                    <button className='rounded-md bg-gray-600 text-white font-bold p-3 w-full mt-5  hover:bg-gray-400' 
                    onClick={()=>{
                        this.setState({page:""})
                    }}>Cancel</button>
                </div>
            </div>
            :
            <></>
          }
          
          {
            this.state.page=="winners"?
            <div className=' w-full max-w-md '>
                <h1 className='text-center font-bold text-3xl mb-5'>Winners</h1>
                
                {
                    this.state.winners.map((prize,index)=>{
                        return(
                            <div key={index} className='bg-white p-6 rounded-md mt-2 mb-5 shadow'>
                                {prize.username==this.state.user.username?
                                    <div>
                                        <h2 className='text-3xl font-bold text-center mb-4'>Congratulations!</h2> 
                                        <h2 className='text-2xl font-bold text-center mb-4'>You, {prize.username}, Have Won The</h2> 
                                        <h2 className='text-2xl font-bold text-center'>{prize.title}</h2>
                                    </div>

                                    :
                                        <div>
                                            <h2 className='text-2xl font-bold text-center mb-4'>Better luck next time!</h2> 
                                            <h2 className='text-3xl font-bold text-center mb-4'>Congratulations to {prize.username}!</h2> 
                                            <h2 className='text-2xl font-bold text-center'>They have Won The {prize.title}</h2>
                                        </div>
                                }
                                <div className='flex justify-around mt-4'>

                                    
                                    <div className='text-center'>
                                        <p className='text-xs font-bold'>Total Entries</p>
                                        <h2 className='text-xl font-bold'>{prize.entriesNeeded}</h2>
                                    </div>
                                </div>
                                
                            </div>
                        )
                    })
                }
            </div>
            :
            <></>
          }
          

        </main>
        <Nav />
      </>
    )
  }
}

export default Raffle;