import React from 'react';
import Nav from '../components/nav';
import { Link } from 'react-router-dom'; // Assuming you are using react-router for navigation

function NotFound() {
  return (
    <div className="flex flex-col items-center  min-h-screen bg-gray-100 mt-20">
      <Nav />
      <h1 className="text-4xl font-bold text-gray-800">404 Page Not Found</h1>
      <p className="text-lg text-gray-600 mt-4">We're sorry, the page you requested could not be found.</p>
      <Link to="/" className="mt-6 px-6 py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-150 ease-in-out">
        Go Home
      </Link>
    </div>
  );
}

export default NotFound;
