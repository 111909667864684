import React from 'react';
import {auth,getUser} from '../config/firebase'
import {signOut} from "firebase/auth"
import { Link } from 'react-router-dom';


// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
class Header extends React.Component {
constructor(props) {
    super(props);

    this.state = {
        homePage:"",
        showLogout:false,
        downloadAppEvent:null
    };
}
    async logout(){
        console.log("logout")
        try{
            await signOut(auth);
            window.location.replace("/home");
        } catch (error){
            console.log(error)
        }
    }
     componentDidMount() {
        window.addEventListener("beforeinstallprompt", (event) => {
            console.log("event occured");
    
            event.preventDefault();
    
            this.setState({
              downloadAppEvent: event,
            });
          });
        getUser().then((user)=>{
             this.setState({homePage:"/", showLogout:true})
        }).catch((error)=>{
            this.setState({homePage:"/home"})
            console.log(error)
        });
        
    }

    render() {
        getUser().then(()=>{
           
        })
        return (
            
            <header className='bg-white  shadow-lg w-full p-2 flex justify-center items-center'>
                <div className='w-full max-w-md flex justify-between'>
                    <Link to={this.state.homePage} className='flex w-8/12 justify-start'>
                        {/* <svg  xmlns="http://www.w3.org/2000/svg" width="27" height="36" viewBox="0 0 27 36" fill="none">
                            <path className="text-sky-600" d="M15.1664 35.1C18.7734 30.5859 27 19.6453 27 13.5C27 6.04688 20.9531 0 13.5 0C6.04688 0 0 6.04688 0 13.5C0 19.6453 8.22656 30.5859 11.8336 35.1C12.6984 36.1758 14.3016 36.1758 15.1664 35.1ZM13.5 18C11.018 18 9 15.982 9 13.5C9 11.018 11.018 9 13.5 9C15.982 9 18 11.018 18 13.5C18 15.982 15.982 18 13.5 18Z" fill="rgb(2 132 199)"/>
                        </svg> */}

                        <img style={{height:"40px"}} src='/pinDasherPin.png' alt='logo'></img>
                        
                        <h1 className="text-4xl text-sky-900 ml-1">PinDasher</h1>
                        
                    </Link>
                    {this.state.downloadAppEvent ? 
                        <button
                        style={{width:"50px"}}
                        className="download-button mr-4"
                        aria-label="Download VIN Oracle as a web app."
                        title="Download VIN Oracle"
                        onClick={() => {
                            if (this.state.downloadAppEvent) {
                                this.state.downloadAppEvent.prompt(); // Show the install prompt
                                this.state.downloadAppEvent.userChoice.then((choiceResult) => {
                                    if (choiceResult.outcome === 'accepted') {
                                        console.log('User accepted the A2HS prompt');
                                    } else {
                                        console.log('User dismissed the A2HS prompt');
                                    }
                                    this.setState({ downloadAppEvent: null }); // Remove the event once handled
                                });
                            }
                        }}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"/></svg>
                        </button>
                        : null}
                    
                    {
                        this.state.showLogout?
                        <button className='text-white font-bold py-2 px-4  rounded bg-sky-900 text-white font-bold p-2 w-3/12  hover:bg-sky-600' onClick={()=>{
                            this.logout()
                            
                        }}>Logout</button> 
                        :
                        <Link to="/login" className='w-3/12 flex justify-end'>
                            <button className='text-white font-bold py-2 px-4  rounded  text-white font-bold p-3  bg-sky-900 hover:bg-sky-700'>Login</button>
                        </Link>
                    }

                </div>
            </header>
        );
    }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
// #endregion

export default Header;